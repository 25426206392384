import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { meCheckHandlerCreateByProductNamesWithExpiredAt, } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { store } from '~/pages/heineken_template/_private/store';
import { Freeman_TopBar } from './freeman_TopBar';
import { Freeman_SidePane2 } from './freeman_SidePane2';
import { Freeman_Row } from './freeman_Row';
import { freeman_initStrategies } from './freeman_initStrategies';
import { Freeman_SidePane1 } from './freeman_SidePane1';
import { useSignalrStart2_1 } from '~/modules/SDK/Signalr/useSignalrStart2_1';
import useMedia from '~/hooks/useMedia';
import { InvestmentLoginView } from '~/modules/investment-consultant/loginPage/InvestmentLoginView';
//import './component/StockKbarBox.css'
export const freeman_init = (templateProps) => {
    freeman_initStrategies();
    useThemeStore.setState({ theme: 'dark' });
    store.charting.setThemeMode(useThemeStore.getState().theme);
    templateProps.permissions.pageview = meCheckHandlerCreateByProductNamesWithExpiredAt([
        'web',
        'youtube',
    ]);
    store.charting.darkOverrides = {
        'mainSeriesProperties.candleStyle.downColor': '#429488',
        'mainSeriesProperties.candleStyle.upColor': '#e15241',
        'mainSeriesProperties.candleStyle.wickDownColor': '#dedede',
        'mainSeriesProperties.candleStyle.wickUpColor': '#dedede',
        'mainSeriesProperties.candleStyle.borderDownColor': '#00ff00',
        'mainSeriesProperties.candleStyle.borderUpColor': '#ff0000',
        'mainSeriesProperties.showPriceLine': false,
        'mainSeriesProperties.showCountdown': false,
        'paneProperties.horzGridProperties.style': 1,
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.topMargin': 15,
        'paneProperties.bottomMargin': 15,
        'paneProperties.vertGridProperties.color': '#2f2f2f',
        'paneProperties.horzGridProperties.color': '#2f2f2f',
        'paneProperties.backgroundType': 'gradient',
        'paneProperties.backgroundGradientStartColor': '#141721',
        'paneProperties.backgroundGradientEndColor': '#141721',
        'timeScale.rightOffset': 0,
        'scalesProperties.fontSize': 12,
        'scalesProperties.textColor': '#dedede',
        'scalesProperties.lineColor': '#dedede',
    };
    store.charting.widgetOptions = {
        ...store.charting.widgetOptions,
        symbol: 'TXAM-1',
        interval: '1',
        enableVolumeIndicator: false,
        disabledHeaderWidget: false,
        disabledLeftToolbar: true,
        disabledTimeframesToolbar: true,
        disabledHeaderChartType: true,
        disabledHeaderCompare: true,
        disabledGoToDate: true,
        disabledHeaderSaveload: true,
        overrides: {
            ...store.charting.darkOverrides,
        },
    };
    templateProps.layout.cssset = css `
    grid-template-rows: 48px 1fr 336px;
    grid-template-columns: 280px 1fr 464px;
    place-content: flex-start;

    .Chart {
    }

    grid-template-areas:
      'Drawer1 Row1 Drawer2'
      'Drawer1 Chart Drawer2'
      'Drawer1 Row2 Drawer2';

    ${createIPadCss(css `
      grid-template-rows: 48px 336px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Row2 Row2'
        'Chart Chart';
    `)}

    ${createMobileCss2(css `
      grid-template-rows: 48px 1fr;
      grid-template-columns: 1fr;
      grid-template-areas:
        'Row1 Row1'
        'Chart Chart';
    `)}
  `;
    templateProps.useLayout = function useLayout(layouts) {
        const { isPc, isPad } = useMedia();
        return {
            ...layouts,
            Drawer1: <Freeman_SidePane1 />,
            Drawer2: <Freeman_SidePane2 />,
            Row1: <Freeman_TopBar />,
            Row2: isPc || isPad ? <Freeman_Row /> : null,
            login: (<InvestmentLoginView copyright='Copyright © 2024 Freeman.' activationCode={false} purchaseLink='https://freeman1688.oen.tw'/>),
        };
    };
    templateProps.hooks.add(useSignalrStart2_1);
};
