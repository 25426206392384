import { memo, useEffect, useMemo, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis, } from 'recharts';
import useSWR from 'swr-0-5-6';
import dayAPI from '~/utils/dayAPI';
import { proxy, useSnapshot } from 'valtio';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
import { css } from '@emotion/react';
import { fill_vertical_main_center } from '~/modules/AppLayout/FlexGridCss';
export const estimatedVolumeStore = proxy({
    value: 0,
});
/** 測試中的預估量 tvapi */
const url = 'https://tvapi.futures-ai.com/history?symbol=TSEA%23estimated_volume&resolution=1';
/** 測試中的function */
const useEstimatedVolume = (options) => {
    const symbol = options?.symbol;
    const resolution = options?.resoultion;
    // const api = urlcat(`${apirc.tvAPIs.baseUrl}/history`, {
    //   symbol,
    //   resolution,
    // })
    const res = useSWR(url, {
        revalidateOnFocus: false,
        refreshInterval: options?.refreshInterval ?? 10000,
    });
    return res;
};
//----------------------------------------------------------------
const volumeYAxisProps = {
    dataKey: 'volume',
    tickSize: 12,
    tick: {
        fontSize: 12,
        fill: '#cccccc',
    },
};
const timeXAxisProps = {
    type: 'number',
    dataKey: 'time',
    tickSize: 12,
    tick: {
        fontSize: 12,
        fill: '#cccccc',
    },
};
const categoryChartMargin = {
    top: 10,
    right: 10,
    left: 10,
    bottom: 0,
};
export const EstimatedVolumeChart = memo(function EstimatedVolume() {
    const { data: rawData } = useEstimatedVolume({
        resoultion: 1,
        symbol: 'TSEA',
        refreshInterval: 10000,
    });
    const dataVolume = rawData?.v;
    const dataTime = rawData?.t;
    const data = useMemo(() => {
        if (!dataVolume || !dataTime || dataVolume.length !== dataTime.length) {
            return [];
        }
        return dataVolume.map((volume, index) => ({
            time: dataTime[index],
            volume,
        }));
    }, [dataVolume, dataTime]);
    const staticState = useSnapshot(staticStore).tradedDate;
    const intraday = staticState.intraday;
    const [startDateTme, setStartDateTime] = useState(dayAPI(intraday).setTime(9.0));
    const [endDateTme, setEndDateTime] = useState(dayAPI(intraday).setTime(13.3));
    const volumeSetPoint = dataVolume?.[90] ?? 0;
    const timeSetPoint = dataTime?.[90] ?? 0;
    const displayVolumeSetPoint = (volumeSetPoint / 1e8).toFixed(0);
    //當電腦時間08:30 || 08:45 重新拿一次最新交易日
    //確保圖表可以更新資料
    useEffect(() => {
        if (dayAPI().format('HH:mm') === '08:30') {
            setStartDateTime(dayAPI(intraday).setTime(9.0));
            setEndDateTime(dayAPI(intraday).setTime(13.3));
        }
    }, [intraday, staticState]);
    //將數值傳出去作為其他元件的數值來源
    const length = data?.length ?? 0;
    useEffect(() => {
        estimatedVolumeStore.value = Number(data?.map(s => s.volume)[length - 1]);
    }, [data, length]);
    const timeRange = [startDateTme.unix(), endDateTme.unix()];
    const CustomizedLabel = (<Label position='center' value={'10:30-' + '預估量:' + displayVolumeSetPoint + '億'} fill={'#ffffff'} fontSize={'14px'} offset={10}/>);
    return (<div css={css `
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      `}>
      <ResponsiveContainer width='100%' height='100%'>
        <ComposedChart data={data} margin={categoryChartMargin}>
          <CartesianGrid stroke='#333333' strokeDasharray='1' fill={'#222222'} verticalPoints={[]} horizontalPoints={[0]}/>
          <XAxis {...timeXAxisProps} tickFormatter={timeStr => dayAPI(timeStr * 1000).format('HH:mm')} domain={timeRange}/>
          <YAxis {...volumeYAxisProps} tickFormatter={volumeStr => volumeStr / 1e8 + '億'}/>
          <Bar dataKey='volume' barSize={16} fill={'#3F51B5'} isAnimationActive={false}/>
          {/** 顯示10:30的預估量標記位置 */}
          {/* <ReferenceLine
        x={timeSetPoint}
        stroke='red'
        label={CustomizedLabel}
      /> */}
          <Tooltip content={<CustomizedTooltip />}/>
        </ComposedChart>
      </ResponsiveContainer>
    </div>);
});
const CustomizedTooltip = ({ payload }) => {
    if (!payload || (payload && payload.length < 1))
        return null;
    const datum = payload[0].payload;
    const time = datum.time;
    const volume = (datum.volume / 1e8).toFixed(2);
    const tooltipCss = css `
    ${fill_vertical_main_center};
    height: 56px;
    border: 1px solid #ffffff;
    padding: 4px;
    border-radius: 4px;
  `;
    return (<div css={tooltipCss}>
      <span>日期時間: {dayAPI(time * 1000).format('MM/DD HH:mm')}</span>
      <span>預估量: {volume + '億'}</span>
    </div>);
};
