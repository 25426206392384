import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_vertical_all_center } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { stockDecimal } from '~/modules/new-version-counsultant/Quote/stockDecimal';
import { Kbar } from '~/pages/edwin/component/Kbar';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { quoteChangeBackGroundColor, quoteChangeColor, quoteChangeType, } from './SymbolQuoteWithChart';
import { useThemeStore } from '~/components/theme/useThemeStore';
export const SymbolQuoteWithKbar = memo(function SymbolQuoteWithKbar(props) {
    const theme = useThemeStore(state => state.theme);
    const quote = useSnapshot(signalrStore2.values.quote)[props.symbol];
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const symbol = quote?.symbol ?? '';
    const close = quote?.close ?? 0;
    const low = quote?.low ?? 0;
    const volume = quote?.volume ?? 0;
    const quoteChange = processQuoteToReadable(quote);
    const change = quoteChange.closeChange;
    const changePrecent = quoteChange.closeChangePercent;
    const changeSymbol = change > 0 ? '+' : '';
    const riseLimt = quote?.limitUpPrice;
    const fallLimt = quote?.limitDownPrice;
    const limit = close === riseLimt || close === fallLimt;
    const prevRef = quote?.prevRef ?? 0;
    const decimal = stockDecimal(close);
    const changeColor = quoteChangeType(change, 0, false);
    const closeColor = quoteChangeType(change, 0, limit);
    return (<classes.container>
        <div css={css `
            ${fill_vertical_all_center};
            padding: 4px;
          `}>
          <Kbar quote={quote}/>
        </div>
        <div css={css `
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-rows: 50% 50%;
          `}>
          <span>{dictionary[symbol]}</span>
          <span>{symbol}</span>
        </div>
        <div>
          <classes.quoteClose className={theme} changeType={closeColor}>
            {close.toFixed(decimal)}
          </classes.quoteClose>
        </div>
        <div>
          <classes.quoteValue className={theme} changeType={changeColor}>
            {changeSymbol}
            {change.toFixed(decimal)}
          </classes.quoteValue>
        </div>
        <div>
          <classes.quoteValue className={theme} changeType={changeColor}>
            {changeSymbol}
            {changePrecent}
          </classes.quoteValue>
        </div>
        <div>
          <classes.quoteValue className={theme} changeType={'flat'}>
            {volume}
          </classes.quoteValue>
        </div>
      </classes.container>);
});
const classes = {
    container: styled.div `
    display: grid;
    grid-template-columns: 10% 20% 17% 17% 17% 17%;
    align-items: center;
    width: 100%;
    height: 40px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  `,
    quoteValue: styled.div `
    display: flex;
    width: 100%;
    jeight: 100%;
    justify-content: end;
    font-size: 13px;
    &.dark {
      color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00ee00', '#ffff00', '#ffffff')};
      background-color: ${props => quoteChangeBackGroundColor(props.changeType, '#ff0000', '#00cc00', 'transparent')};
    }
    &.light {
      color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00cc00', '#ffaa00', '#ffffff')};
      background-color: ${props => quoteChangeBackGroundColor(props.changeType, '#ff0000', '#009900', 'transparent')};
    }
  `,
    quoteClose: styled.div `
    display: flex;
    width: 100%;
    jeight: 100%;
    justify-content: end;
    font-size: 13px;
    &.dark {
      color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00ee00', '#ffff00', '#ffffff')};
      background-color: ${props => quoteChangeBackGroundColor(props.changeType, '#ff0000', '#00cc00', 'transparent')};
    }
    &.light {
      color: ${props => quoteChangeColor(props.changeType, '#ff0000', '#00cc00', '#ffaa00', '#ffffff')};
      background-color: ${props => quoteChangeBackGroundColor(props.changeType, '#ff0000', '#009900', 'transparent')};
    }
  `,
};
